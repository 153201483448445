.test {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    text-align: center;
    // max-width: fit-content;
}
.departure {
    .react-datepicker-wrapper {
        width: 100%;
        .react-datepicker__input-container {
            input {
                width: 100%;
                outline: none;
                border-radius: 4px;
                height: 38px;
                padding-left: 5px;
                border: 1px solid #ced4da !important;
            }
        }
    }
}
.icon_calender {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #495057;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 4.5px;
}
.react-datepicker__tab-loop {
    .react-datepicker-popper {
        .react-datepicker {
            .react-datepicker__month-container {
                .react-datepicker__header {
                    .react-datepicker__current-month {
                        font-size: 16px;
                    }
                    .react-datepicker__day-names {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

.card-lt {
    display: block;
    font-weight: 700;
    font-size: 16px;
}
.card-lb {
    display: block;
    font-size: 12px;
}
.card-r,
.card-l {
    display: inline-grid;
    align-items: center;
}
.card-rt {
    display: block;
    font-size: 12px;
}
.card-rb {
    display: block;
    font-size: 12px;
}
.list-div {
    box-shadow:
        rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    overflow: hidden;
}

.assignment-btn {
    font-size: 14px !important;
    padding: 4px 0px 4px 0px !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
}

.head-div {
    background-color: lightgrey;
}

.sg-card-lt {
    display: block;
    font-size: 16px;
    font-weight: bold;
}

.sg-card-rt {
    display: block;
    font-size: 16px;
    font-weight: bold;
}
.sg {
    display: block;
    font-size: 14px;
}
.bg-work-ticket {
    background-color: blue !important;
}
.ttl {
    font-weight: 600;
}
.content-section {
    padding-bottom: 70px;
}

.oe-list:nth-child(odd) {
    background: #e2e2e2;
}

.oe-list:nth-child(even) {
    background: transparent;
}
.font-600 {
    font-weight: 600;
}
