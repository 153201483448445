$text-color: whitesmoke;
$background-color: #f2f2f2;
$icon-color: #f2f2f2;
$active-text: #232323;

.headerDiv {
    background-color: #232323;
}
.navbar {
    background-color: #444444;
    .navbar-brand {
        width: 250px;
        height: 100px;
        .logo {
            width: 140px;
        }
    }
    .navbar-toggler {
        outline: none;
        margin-right: 8px;
        .menu-icon {
            color: #ffffff;
        }
    }
    .navbar-nav {
        li {
            a.nav-link {
                background-color: #444444;
                //width: 165px;
                width: 170px;
                height: 100px;
                i {
                    color: $icon-color;
                }
                span {
                    color: $text-color;
                    i {
                        color: $text-color;
                    }
                }
                &.active {
                    background-color: #232323;
                }
                &:hover {
                    background-color: #232323;
                }
                &:active {
                    background-color: #232323;
                }
                &:focus {
                    background-color: #232323;
                }
            }
        }
    }
    .rightSide {
        display: block;
        margin-right: 20px;
        .dropdown {
            background-color: #444444;
            border-radius: 20px 20px 0 0;
            padding: 5px 10px;
            &.show {
                background-color: #232323;
                box-shadow: 0 -1px 0px #232323;
            }
            &:focus {
                background-color: #232323;
                box-shadow: 0 -1px 0px #232323;
            }
            a {
                color: $icon-color;
                span:nth-of-type(2) {
                    width: 30px;
                    height: 30px;
                    background-color: $text-color;
                    color: $background-color;
                }
            }
            .dropdown-menu {
                width: 100%;
                background-color: #232323;
                border-radius: 0 0 20px 20px;
                border: none;
                a.dropdown-item {
                    color: $text-color;
                    text-decoration: underline;
                    border: none;
                    white-space: unset;
                    i {
                        color: $background-color;
                    }
                    &:hover {
                        background-color: $background-color;
                        color: $active-text;
                        i {
                            color: $active-text;
                        }
                    }
                }
                a.dropdown-item:last-child {
                    border-radius: 0 0 20px 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .headerDiv {
        position: relative;
    }

    .navbar {
        // position: absolute !important;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
        border-bottom: 1px solid $background-color;
        .navbar-brand {
            width: 150px;
            height: 70px;
            .logo {
                width: 140px;
            }
        }
        .navbar-nav {
            li {
                a.nav-link {
                    width: 100%;
                    height: auto;
                    span {
                        width: 100%;
                    }
                }
            }
        }
    }
    .rightSide {
        width: 100%;
        .dropdown {
            .dropdown-toggle {
                &::after {
                    margin-left: auto !important;
                }
                .adminName {
                    display: block !important;
                }
            }
        }
    }
    .navbar .rightSide .dropdown {
        border-radius: 0;
    }
    .bottomHeader {
        padding-top: 73px;
        overflow-x: scroll;
        margin-left: 5px !important;
    }
    .mainMenuIcon {
        font-size: 1.5em !important;
    }
}
@media screen and (max-width: 1650px) {
    .navbar {
        background-color: #444444;
        .navbar-brand {
            width: 200px;
            height: 100px;
            .logo {
                width: 140px;
            }
        }
        .navbar-nav {
            li {
                a.nav-link {
                    background-color: #444444;
                    width: 120px;
                    height: 100px;
                }
            }
        }
    }
}
@media screen and (max-width: 1299px) {
    .navbar {
        // position: absolute !important;
        // top: 0;
        // left: 0;
        width: 100%;
        z-index: 10;
        border-bottom: 1px solid $background-color;
        .navbar-brand {
            width: 150px;
            height: 70px;
            .logo {
                width: 140px;
            }
        }
        .navbar-nav {
            li {
                a.nav-link {
                    width: 100%;
                    height: auto;
                    flex-direction: row !important;
                    padding: 12px 16px;
                    span {
                        width: 100%;
                        justify-content: space-between !important;
                    }
                }
            }
        }
    }
    .bottomHeader {
        overflow-x: visible;
        margin-left: 5px !important;
        flex-wrap: wrap;
    }
}

.bottomHeader {
    background-color: #232323;
    margin-left: 250px;
    a.badge {
        background-color: transparent;
        color: $text-color;
        font-weight: normal;
        font-size: 16px;
        cursor: pointer;
        i {
            color: $background-color;
        }
        &:hover {
            background-color: $background-color;
            color: $active-text;
            i {
                color: $active-text;
            }
        }
        &.active {
            background-color: $background-color;
            color: $active-text;
            i {
                color: $active-text;
            }
        }
    }
    .dropdown {
        background-color: transparent;
        border-radius: 20px 20px 0 0;
        a {
            width: 234px;
            color: $text-color;
            span i,
            i {
                color: $background-color;
            }
        }
        &.show {
            background-color: $background-color;
            top: -1px;
            a,
            i {
                color: $text-color;
            }
        }
        &:hover,
        &:focus {
            background-color: $background-color;
            a,
            a span i,
            a i {
                color: $text-color;
            }
        }
        .dropdown-menu {
            width: 100%;
            background-color: #444444;
            border-radius: 0 0 20px 20px;
            border: none;
            a.dropdown-item {
                z-index: 15;
                color: $text-color;
                text-decoration: underline;
                border: none;
                i {
                    color: $background-color;
                }
                &:hover,
                &.active {
                    background-color: $background-color;
                    // color: #444444;
                    i {
                        color: $text-color;
                    }
                }
            }
            a.dropdown-item:last-child {
                border-radius: 0 0 20px 20px;
            }
        }
        //&:hover {
        //  .dropdown-menu {
        //    display: block;
        //  }
        //}
        &.active {
            background-color: $background-color;
            a,
            a span i,
            a i {
                color: $text-color;
            }
        }
    }
}
.mainMenuIcon {
    font-size: 2em;
}

@media (max-width: 1299.98px) {
    .navbar-expand-xlg > .container,
    .navbar-expand-xlg > .container-fluid,
    .navbar-expand-xlg > .container-sm,
    .navbar-expand-xlg > .container-md,
    .navbar-expand-xlg > .container-lg,
    .navbar-expand-xlg > .container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 1300px) {
    .navbar {
        flex-wrap: nowrap !important;
    }
    .navbar-expand-xlg {
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    .navbar-expand-xlg .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .navbar-expand-xlg .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-xlg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-xlg > .container,
    .navbar-expand-xlg > .container-fluid,
    .navbar-expand-xlg > .container-sm,
    .navbar-expand-xlg > .container-md,
    .navbar-expand-xlg > .container-lg,
    .navbar-expand-xlg > .container-xl {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
    .navbar-expand-xlg .navbar-nav-scroll {
        overflow: visible;
    }
    .navbar-expand-xlg .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
    .navbar-expand-xlg .navbar-toggler {
        display: block;
    }
}

@media only screen and (max-width: 992px) {
    .navbar-toggler {
        display: block !important;
    }
}
