.test {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    text-align: center;
    // max-width: fit-content;
}
.departure {
    .react-datepicker-wrapper {
        width: 100%;
        .react-datepicker__input-container {
            input {
                width: 100%;
                outline: none;
                border-radius: 4px;
                height: 38px;
                padding-left: 5px;
                border: 1px solid #ced4da !important;
            }
        }
    }
}
.icon_calender {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #495057;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 4.5px;
}
.react-datepicker__tab-loop {
    .react-datepicker-popper {
        .react-datepicker {
            .react-datepicker__month-container {
                .react-datepicker__header {
                    .react-datepicker__current-month {
                        font-size: 16px;
                    }
                    .react-datepicker__day-names {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

.rsw-toolbar {
    display: none !important;
}
