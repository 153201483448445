.date-filter {
    display: flex;
    justify-content: right;
    text-align: right;
    .date-filter-input {
        margin-left: 2px;
        border: 1px solid lightgray;
        border-radius: 0.25rem;
        display: flex;
        align-items: center;
        label {
            //width: 100%;
            margin: 0;
            .react-datepicker-wrapper {
                width: 100%;
            }
        }
        .icon_calender {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #495057;
            background-color: #e9ecef;
            border: 1px solid #ced4da;
            border-left: none;
            padding: 10px 12px;
        }
        span {
            cursor: pointer;
            i {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #495057;
                background-color: #e9ecef;
                border: 1px solid #ced4da;
                //border-left: none;
                padding: 8px 12px;
                height: 38px;
            }
        }
    }
}

.green-check-mark {
    color: green;
}

.note-text {
    color: #000000;
    font-weight: bold;
    @media (max-width: 576px) {
        text-align: center;
    }
}

.sg-card-lt {
    display: block;
    font-size: 16px;
    font-weight: bold;
}

.dtail {
    font-size: 17px;
    display: block;
    font-weight: 500;
}

.dtail-bold {
    font-size: 19px;
    font-weight: bold;
    display: block;
}
.content-section {
    padding-bottom: 70px;
}
