#root {
    // Scss variables which we gonna assign using
    // useState and JavaScript in reactJS
    $background-color-custom: grey;
    $background-color-active: #fff;
    $background-color-button: #fff;
    $text-color: #262833;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-template-rows: auto;
}
.calendar-wrapper .fc .fc-header-toolbar {
    background-color: var(--background-color-custom) !important;
}

button.btn.btn-primary.btn.btn-primary {
    background-color: var(--background-color-button) !important;
}
.calendar-wrapper .fc .fc-header-toolbar .fc-toolbar-chunk .fc-button .fc-icon {
    color: white !important;
}
.calendar-wrapper {
    .fc {
        .fc-header-toolbar {
            background-color: #444;
            padding: 8px;
            margin: 0;
            justify-content: center;
            .fc-toolbar-chunk {
                .fc-toolbar-title {
                    font-size: 28px;
                    font-weight: 700;
                    color: #ffffff;
                    margin: 0 16px;
                    @media screen and (max-width: 768px) {
                        font-size: 18px;
                        margin: 0;
                    }
                }
                .fc-button {
                    background-color: transparent;
                    border: none;
                    .fc-icon {
                        color: #2e9bd6;
                        font-weight: bold;
                        font-size: 36px;
                    }
                }
            }
        }
        .fc-view-harness {
            .fc-view {
                .fc-scrollgrid {
                    border-top: none;
                    .fc-scroller-harness {
                        .fc-col-header {
                            .fc-col-header-cell {
                                border: none;
                                background-color: #253c59;
                                .fc-col-header-cell-cushion {
                                    font-size: 20px;
                                    color: #ffffff;
                                    padding: 4px;
                                    @media screen and (max-width: 1199px) {
                                        font-size: 15px;
                                    }
                                    @media screen and (max-width: 992px) {
                                        font-size: 14px;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        width: 50px;
                                    }
                                }
                            }
                        }
                        .fc-scrollgrid-sync-table {
                            .fc-daygrid-day {
                                height: 15%;
                                .fc-daygrid-day-frame {
                                    .fc-daygrid-day-top {
                                        flex-direction: row;
                                        .fc-daygrid-day-number {
                                            font-size: 20px;
                                            @media screen and (max-width: 576px) {
                                                font-size: 12px;
                                            }
                                        }
                                    }
                                    .fc-daygrid-day-events {
                                        .fc-daygrid-event-harness {
                                            padding: 1rem;
                                            .fc-daygrid-event {
                                                // background-color: #f59108;
                                                border-radius: 15px;
                                                height: 100px;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                white-space: nowrap;
                                                padding: 4px 8px;
                                                margin: 2px 0;
                                                cursor: pointer;
                                                .fc-event-title {
                                                    font-size: 13px;
                                                    font-weight: 500;
                                                    // color: #ffffff;
                                                    text-transform: capitalize;
                                                    @media screen and (max-width: 767px) {
                                                        text-overflow: ellipsis;
                                                    }
                                                }
                                                &:hover {
                                                    background-color: #2e9bd6;
                                                    .fc-event-title {
                                                        text-decoration: underline;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.date-filter {
    display: flex;
    justify-content: right;
    text-align: right;
    .date-filter-input {
        margin-left: 2px;
        border: 1px solid lightgray;
        border-radius: 0.25rem;
        display: flex;
        align-items: center;
        label {
            //width: 100%;
            margin: 0;
            .react-datepicker-wrapper {
                width: 100%;
            }
        }
        .icon_calender {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #495057;
            background-color: #e9ecef;
            border: 1px solid #ced4da;
            border-left: none;
            padding: 10px 12px;
        }
        span {
            cursor: pointer;
            i {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #495057;
                background-color: #e9ecef;
                border: 1px solid #ced4da;
                //border-left: none;
                padding: 8px 12px;
                height: 38px;
            }
        }
    }
}

.green-check-mark {
    color: green;
}

.event-container {
    flex-wrap: wrap;
    @media (max-width: 576px) {
        margin: 0;
    }
}
.event-type,
.event-container {
    display: flex;
    margin: 1rem;
    @media (max-width: 576px) {
        margin: 0.25rem;
        font-size: small;
    }
}

.event-type {
    @media (max-width: 576px) {
        align-items: center;
    }
}

.event-type div {
    margin: 0.2rem;
}
.green-div-box div {
    background-color: green;
    padding: 0.5rem;
    padding-left: 4rem;
    padding-right: 4rem;
    border-radius: 50px;
    color: #fff;
    @media (max-width: 576px) {
        padding: 0.2rem 2rem;
    }
}
.purple-div-box div {
    background-color: #800080;
    padding: 0.5rem;
    padding-left: 4rem;
    padding-right: 4rem;
    border-radius: 50px;
    color: #fff;
    @media (max-width: 576px) {
        padding: 0.2rem 2rem;
    }
}
.purple-div-title {
    align-self: center;
}
.green-div-title {
    align-self: center;
}
.green-div-title span {
    font-weight: 600;
}
.yellow-div-box div {
    background-color: #ffa500;
    padding: 0.5rem;
    padding-left: 4rem;
    padding-right: 4rem;
    border-radius: 50px;
    color: #fff;
    @media (max-width: 576px) {
        padding: 0.2rem 2rem;
    }
}
.yellow-div-title {
    align-self: center;
}
.yellow-div-title span {
    font-weight: 600;
}

.red-div-box div {
    background-color: #ff0000;
    padding: 0.5rem;
    padding-left: 4rem;
    padding-right: 4rem;
    border-radius: 50px;
    color: #fff;
    @media (max-width: 576px) {
        padding: 0.2rem 2rem;
    }
}
.red-div-title {
    align-self: center;
}
.red-div-title span {
    font-weight: 600;
}

.grey-div-box div {
    background-color: grey;
    padding: 0.5rem;
    padding-left: 4rem;
    padding-right: 4rem;
    border-radius: 50px;
    color: #fff;
    @media (max-width: 576px) {
        padding: 0.2rem 2rem;
    }
}
.grey-div-title {
    align-self: center;
    span {
        font-weight: 600;
    }
}

.blue-div-box div {
    background-color: #00a1ff;
    padding: 0.5rem;
    padding-left: 4rem;
    padding-right: 4rem;
    border-radius: 50px;
    color: #fff;
    @media (max-width: 576px) {
        padding: 0.2rem 2rem;
    }
}
.blue-div-title {
    align-self: center;
    span {
        font-weight: 600;
    }
}

.datetimepicker-input {
    @media (max-width: 576px) {
        text-align: center;
    }
}
