.active,
.item:hover {
    cursor: pointer;
    color: #fff !important;
    background-color: #aeda2b !important;
}
.highlight {
    background-color: #aeda2b;
    color: black;
    border-radius: 0.25rem;
}
