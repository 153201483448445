.warning-modal {
    .modal-dialog {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .modal-header {
        padding: 12px;
        border: none;
        justify-content: space-between;
        .closeButton {
            font-size: 28px;
            padding: 5px 12px 0 0;
            color: gray;
            cursor: pointer;
        }
    }
    .modal-body {
        padding-top: 0;
    }
}
